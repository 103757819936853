// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getAppOrderList = (params) => {
    return postRequest('/busi/appOrder/getByCondition', params)
}
// 添加
export const addAppOrder = (params) => {
    return postRequest('/busi/appOrder/insert', params)
}
// 编辑
export const editAppOrder = (params) => {
    return postRequest('/busi/appOrder/update', params)
}
// 删除
export const deleteAppOrder = (params) => {
    return postRequest('/busi/appOrder/delByIds', params)
}