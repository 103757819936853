<template>
    <div class="appOrderAddEdit">
        <!-- Drawer抽屉 -->
        <Drawer :title="title" v-model="visible" width="720" on-close="handleClose()" :mask-closable="false">
            <Form ref="form" :model="form" :label-width="120" :rules="formValidate" label-position="left">
                <FormItem label="交易订单" prop="tradeno">
                    <Input v-model="form.tradeno" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="邮箱" prop="email">
                    <Input v-model="form.email" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="imei1" prop="imei1">
                    <Input v-model="form.imei1" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="imei2" prop="imei2">
                    <Input v-model="form.imei2" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="随机号" prop="checknum">
                    <Input v-model="form.checknum" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="回调次数" prop="times">
                    <Input v-model="form.times" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="购买类型" prop="buytype">
                    <Input v-model="form.buytype" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="记录时间" prop="instime">
                    <Input v-model="form.instime" clearable style="width:100%"/>
                </FormItem>
                <FormItem label="更新时间" prop="updtime">
                    <Input v-model="form.updtime" clearable style="width:100%"/>
                </FormItem>
                <Form-item class="br">
                    <Button @click="handleSubmit" :loading="submitLoading">保存</Button>
                    <Button @click="handleClose">关闭</Button>
                </Form-item>
            </Form>
        </Drawer>
    </div>
</template>
<script>
	// 根据你的实际请求api.js位置路径修改
	import {
		addAppOrder,
		editAppOrder
	} from "@/api/busi/data-manage/appOrder";
	export default {
		name: "appOrderAddEdit",
		components: {},
		props: {
			data: Object,
			addEditFlag: String,
			value: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				submitLoading: false, // 表单提交状态
				// TODO
				title: "本文标题",
				visible: this.value,
                form: { // 添加或编辑表单对象初始化数据
                    tradeno: "",
                    email: "",
                    imei1: "",
                    imei2: "",
                    checknum: "",
                    times: "",
                    buytype: "",
                    instime: "",
                    updtime: "",
                },
				formExt: {
				},
                // 表单验证规则
                formValidate: {
                }
            };
        },
		methods: {
			init() {
				// 编辑情况下
				if (this.addEditFlag == "2") {
					this.form = this.data;
				}else{
					delete this.form.id;
				}
			},
			setCurrentValue(value) {
				if (value === this.visible) {
					this.$refs.form.resetFields();
				 return;
				}
				this.init();
				this.visible = value;
			},
			handleReset() {
				this.$refs.form.resetFields();
			},
			handleSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						if (this.addEditFlag == "1") {
							addAppOrder(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}else{
							editAppOrder(this.form).then(res => {
								this.submitLoading = false;
								if (res.success) {
									this.$Message.success("操作成功");
									this.$emit("on-submit", true);
									this.visible = false;
								}
							});
						}
					}
				});
			},
			handleClose(){
				this.visible = false;
			}

		},
		watch: {
			value(val) {
				this.setCurrentValue(val);
			},
			visible(value) {
				this.$emit("input", value);
			},
		},
		mounted() {
			this.init();
		}
	};
</script>

<style lang="less">
// 建议引入通用样式 具体路径自行修改
//@import "../../../styles/table-common.less";
</style>